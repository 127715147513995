import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  Radio,
  Select,
  Switch,
  InputNumber,
  message,
} from "antd";
import service from "../../modules/notification/service";
const { Option } = Select;

const AddModal = (props) => {
  const { visible, onCancel, onCreate } = props;
  const [form] = Form.useForm();

  return (
    <Modal
      visible={visible}
      title="Create a new notification"
      okText="Create"
      onCancel={() => {
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            service.add(values).then((d) => {
              message.info("success");
              form.resetFields();
            });
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ status: "ACTIVE", type: "none" }}
      >
        <Form.Item
          name="type"
          rules={[{ required: true, message: "Please input  type!" }]}
        >
          <Select>
            <Option value="package">package</Option>
            <Option value="item">item</Option>
            <Option value="rent">rent</Option>
            <Option value="order">order</Option>
            <Option value="ads">ads</Option>
            <Option value="discount">discount</Option>
            <Option value="document">document</Option>
            <Option value="none">none</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Title" name="title" rules={[{ required: true }]}>
          <Input placeholder="Notification title" />
        </Form.Item>
        <Form.Item label="Body" name="body" rules={[{ required: true }]}>
          <Input placeholder="Notification body" />
        </Form.Item>
        <Form.Item
          label="App"
          name="app"
          rules={[{ required: true, message: "Please input app!" }]}
        >
          <Radio.Group>
            <Radio value="user">user</Radio>
            <Radio value="vehicle">vehicle</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item name="image" label="Picture">
          <Input placeholder="image url" />
        </Form.Item>
        <Form.Item label="Click action" name="click_action">
          <Input placeholder="click action" />
        </Form.Item>
        <Form.Item label="Topic" name="topic">
          <Input placeholder="topic" />
        </Form.Item>

        <Form.Item name="external" label="External" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item label="Link" name="link">
          <Input placeholder="link (url)" />
        </Form.Item>
        <Form.Item
          name="user_id"
          label="User Id (owner of notification)"
          rules={[{ required: true, message: "Please input user id!" }]}
        >
          <Input placeholder="user id" />
        </Form.Item>
        <Form.Item name="source_id" label="Source Id (sender of notification)">
          <Input placeholder="source id" />
        </Form.Item>
        <Form.Item
          name="status"
          rules={[{ required: true, message: "Please input  status!" }]}
        >
          <Select>
            <Option value="ACTIVE">ACTIVE</Option>
            <Option value="INACTIVE">INACTIVE</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="push_noti"
          label="Push notification"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddModal;
