import React, { useState } from "react";
import { Modal, Form, Input, Switch, Select, InputNumber } from "antd";

import DebounceSelect from "../../../components/shared/DebounceSelect";
import service from "../service";
const { Option } = Select;
const { TextArea } = Input;

async function fetchCategoryList(q) {
  return service.category.list({ start: 0, limit: 10, q }).then((body) =>
    body.data.map((c) => ({
      label: c.name,
      value: c._id,
    }))
  );
}

const AddModal = (props) => {
  const { visible, onCancel, onCreate, image } = props;

  const [category, setCategory] = useState(null);

  const [form] = Form.useForm();

  return (
    <Modal
      visible={visible}
      title="Create a new SubCategory"
      okText="Create"
      width={600}
      onCancel={() => {
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            let status = values.status ? "ACTIVE" : "INACTIVE";
            let picture = {
              location: values.location,
              name: values.picture_name,
              type: values.picture_type,
              options: values.options,
            };
            values.picture = picture;
            values.category_id = values.category_id.value;
            values.status = status;
            delete values.location;
            delete values.picture_name;
            delete values.picture_type;
            delete values.options;
            // console.log(values);
            onCreate(values);
            // form.resetFields();
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          location: process.env.REACT_APP_UPLOAD_LOCATION,
          sizes: [{}],
        }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please input name!",
            },
          ]}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <div className="flex gap-x-9">
          <Form.Item
            label="Score"
            name="score"
            rules={[
              {
                required: true,
              },
            ]}
            initialValue={0}
          >
            <InputNumber />
          </Form.Item>
        </div>
        <Form.Item
          name="location"
          label="Picture location"
          rules={[
            {
              required: true,
              message: "Please input location!",
            },
          ]}
        >
          <Input placeholder="location" />
        </Form.Item>
        <Form.Item
          name="picture_name"
          label="Picture name"
          rules={[
            {
              required: true,
              message: "Please input picture name!",
            },
          ]}
        >
          <Input placeholder="name" />
        </Form.Item>

        <Form.Item
          name="picture_type"
          label="Picture mime-type"
          rules={[
            {
              required: true,
              message: "Please input picture type!",
            },
          ]}
        >
          <Input placeholder="mime-type" />
        </Form.Item>

        <Form.Item
          label="Picture Sizes[multiple]"
          name="options"
          rules={[
            {
              required: true,
              message: "Please select your sizes!",
              type: "array",
            },
          ]}
        >
          <Select mode="multiple" placeholder="Please select image processor">
            <Option value="original">Original</Option>
            <Option value="compressed">Compressed</Option>
            <Option value="avatar">avatar</Option>
            <Option value="1x1">1:1</Option>
            <Option value="4x5">4:5</Option>
            <Option value="2x3">2:3</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Select category"
          name="category_id"
          rules={[
            {
              required: true,
              message: "Please select category!",
            },
          ]}
        >
          <DebounceSelect
            showSearch
            mode="single"
            value={category}
            placeholder="Select category"
            fetchOptions={fetchCategoryList}
            onChange={(newValue) => {
              setCategory(newValue);
            }}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
        <Form.Item
          name="desc"
          label="Description"
          rules={[
            {
              required: true,
              message: "Please input description!",
            },
          ]}
        >
          <TextArea placeholder="Description" />
        </Form.Item>
        <div className="flex gap-x-9">
          <Form.Item name="status" label="Status" valuePropName="checked">
            <Switch />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default AddModal;
