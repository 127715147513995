import Call from "../../util/Call";

const login = async (data) => {
  let d = await Call({
    path: "user/login",
    method: "post",
    data,
  });
  localStorage.setItem("token", d.access_token);
  return d;
};

const logout = () => {
  localStorage.removeItem("token");
};

const user = () => {
  return Call({
    path: "user/me",
    method: "get",
  });
};

const list = (params) => {
  return Call({
    path: "user/",
    method: "get",
    params,
  });
};

const update = (id, data) => {
  return Call({
    path: "user/" + id,
    method: "patch",
    data,
  });
};

const updateVerification = (id, verified) => {
  return Call({
    path: "user/" + id + "/verification",
    method: "patch",
    data: { is_verified: verified },
  });
};

const add = (data) => {
  return Call({
    path: "user/",
    method: "post",
    data,
  });
};

const total = () => {
  return Call({
    path: "user/total",
    method: "post",
  });
};
export default {
  login,
  logout,
  user,
  list,
  update,
  total,
  updateVerification,
  add,
  address: {
    list: (id) => Call({ path: `user/${id}/address`, method: "get" }),

    add: (data) => Call({ path: "user/address", method: "post", data }),

    update: (id, data) =>
      Call({ path: `user/address/${id}`, method: "patch", data }),

    remove: (id) => Call({ path: `user/address/${id}`, method: "delete" }),
  },

  discount: {
    list: (id) => Call({ path: `user/discount/${id}/list`, method: "get" }),

    addReferal: (data) =>
      Call({ path: "user/discount/referral/admin", method: "post", data }),

    add: (data) => Call({ path: "user/discount/", method: "post", data }),

    update: (id, data) =>
      Call({ path: `user/discount/${id}`, method: "patch", data }),

    remove: (id) => Call({ path: `user/discount/${id}`, method: "delete" }),
  },

  document: {
    list: (id) => Call({ path: `user/${id}/document`, method: "get" }),

    add: (data) => Call({ path: "user/document", method: "post", data }),

    update: (id, data) =>
      Call({ path: `user/document/${id}`, method: "patch", data }),

    changeStatus: (id, data) =>
      Call({ path: `user/document/${id}/status`, method: "patch", data }),

    remove: (id) => Call({ path: `user/document/${id}`, method: "delete" }),
  },
};
