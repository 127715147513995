import React from "react";

const Image = (props) => {
  let { url, style, type, multiple, location, name, options, selected } = props;

  return (
    <div>
      {!multiple ? (
        <img style={style} src={url} />
      ) : options.indexOf(selected) > -1 ? (
        selected === "original" ? (
          <img style={style} src={`${location}${name}.${type.split("/")[1]}`} />
        ) : (
          <img style={style} src={`${location}${name}_${selected}.jpg`} />
        )
      ) : (
        ""
      )}
    </div>
  );
};

export default Image;
