import React, { useState } from "react";
import { Modal, Form, Input, Upload, Icon, Select } from "antd";
import { InboxOutlined } from "@ant-design/icons";
const { Option } = Select;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
const ImageUpload = (props) => {
  const [form] = Form.useForm();

  const [fileList, setfileList] = useState([]);
  const [uploading, setuploading] = useState(false);
  const [previewVisible, setpreviewVisible] = useState(false);
  const [loading, setloading] = useState(false);
  const [previewImage, setpreviewImage] = useState("");
  const [url, seturl] = useState("");
  const [name, setname] = useState("");

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleCancel = () => setpreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setpreviewImage(file.url || file.preview);
    setpreviewVisible(true);
  };

  const { visible, onCancel, onCreate } = props;

  return (
    <Modal
      visible={visible}
      title="Upload file"
      okText="Upload"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            // form.resetFields();
            onCreate(values, fileList).then((d) => form.setFieldsValue(d));
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Image"
          name="image"
          valuePropName="fileList"
          required={true}
          initialValues={{ location: process.env.UPLOAD_LOCATION }}
          getValueFromEvent={normFile}
        >
          <Upload.Dragger
            maxCount={1}
            name="image"
            onPreview={handlePreview}
            beforeUpload={(file) => {
              setfileList([file]);
              return false;
            }}
            onRemove={(file) => {
              const index = fileList.indexOf(file);
              const newFileList = fileList.slice();
              newFileList.splice(index, 1);
              setfileList(newFileList);
            }}
            listType="picture-card"
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">Support for a single only.</p>
          </Upload.Dragger>
          {
            <Modal
              visible={previewVisible}
              footer={null}
              onCancel={handleCancel}
            >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
          }
        </Form.Item>

        <Form.Item label="Location" name="location">
          {<Input placeholder="location" />}
        </Form.Item>

        <Form.Item label="Name" name="name">
          {<Input placeholder="Name" />}
        </Form.Item>

        <Form.Item label="Mime" name="type">
          <Input placeholder="mime-type" />
        </Form.Item>

        <Form.Item
          label="Sizes[multiple]"
          name="sizes"
          rules={[
            {
              required: true,
              message: "Please select your sizes!",
              type: "array",
            },
          ]}
          initialValue={["original"]}
        >
          <Select mode="multiple" placeholder="Please select image processor">
            <Option value="original">Original</Option>
            <Option value="compressed">Compressed</Option>
            <Option value="avatar">avatar</Option>
            <Option value="1x1">1:1</Option>
            <Option value="4x5">4:5</Option>
            <Option value="2x3">2:3</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ImageUpload;
