import ACTION from "./actions";

export default function authReducer(state, action) {
  const result = action.body;
  switch (action.type) {
    case `${ACTION.LOGIN_SUCCESS}`:
      return {
        ...state,
        user: result.user,
      };

    case `${ACTION.USER_LOAD}`:
      return {
        ...state,
        user: result,
        loading: false,
      };

    case `${ACTION.LIST_ROLE}`:
      return {
        ...state,
        roles: result,
      };

    case `${ACTION.SET_IMAGE}`:
      let images =
        state.images && state.images.length
          ? [...state.images, result]
          : [result];
      return {
        ...state,
        images,
      };

    default:
      return state;
  }
}
