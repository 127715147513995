import React from "react";
import { Spin } from "antd";
const Loading = () => (
  <div
    className="loading"
    style={{
      width: "100%",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Spin />
  </div>
);
export default Loading;
