import { Layout, Row, Col } from "antd";
import { NotificationContainer } from "react-notifications";

const { Content, Footer, Header } = Layout;

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
  return (
    // <Layout>
    //   <Content> {props.children}</Content>
    // </Layout>
    <Row type="flex" style={{ height: "100vh" }} align="middle">
      <NotificationContainer />

      <Col
        style={{
          left: "50%",
          top: "10%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {props.children}
      </Col>
    </Row>
  );
}
