import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "../../../hooks/withRouter";

import { Table, Divider, Tag, Modal, Button, message } from "antd";
import { PageHeader } from "@ant-design/pro-layout";

import Service from "../service";
import { AuthContext } from "../../auth/context";
import AddModal from "./add";
import EditModal from "./edit";
import Image from "../../../components/shared/Image";

const { confirm } = Modal;

const SubCategory = (props) => {
  const { user, image } = useContext(AuthContext);
  const columns = [
    {
      title: "Logo",
      key: "picture",
      dataIndex: "picture",
      render: (r) => {
        return (
          <Image
            style={{ width: 100 }}
            multiple={true}
            location={r.location}
            name={r.name}
            type={r.type}
            options={r.options}
            selected="original"
          />
        );
      },
    },
    { title: "Name", dataIndex: "name", key: "name", sorter: false },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (r) => r.name,
    },

    {
      title: "Action",
      key: "action",
      render: (r) => (
        <span>
          <Tag
            color="blue"
            key="edit_tag"
            onClick={(e) => showEditModal(e, r)}
            style={{ cursor: "pointer" }}
          >
            Edit
          </Tag>
          <Divider type="vertical" />
          <Tag
            color="volcano"
            onClick={(e) => onDelete(e, r)}
            key="delete_tag"
            style={{ cursor: "pointer" }}
          >
            Delete
          </Tag>
        </span>
      ),
    },
  ];
  const [visible, setVisible] = useState({ add: false, edit: false });
  const [pagination, setPagination] = useState({
    limit: 10,
    pageSize: 10,
    start: 0,
    current: 1,
  });
  const [subs, setSubs] = useState([]);
  const [sub, setSub] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    get();
  }, []);

  const showEditModal = (e, r) => {
    setVisible({ add: false, edit: true });
    setSub(r);
  };

  const handleCancel = (e) => setVisible({ add: false, edit: false });

  const onDelete = (e, r) => {
    confirm({
      title: "Are you sure delete this Sub ?",
      content: "Delete " + r.name,
      okText: "Yes",
      okSub: "danger",
      cancelText: "No",
      onOk() {
        Service.sub
          .remove(r._id)
          .then((d) => {
            get();
          })
          .catch((e) => {
            message.error(e.message);
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleTableChange = (pg, filters, sorter) => {
    let start = pg.current * pg.pageSize - pg.pageSize;
    let params = {
      limit: pg.pageSize,
      sortField: sorter.field,
      sortOrder: sorter.order == "ascend" ? 1 : -1,
      start,
      ...filters,
    };
    get(params);
  };

  const get = async (params) => {
    setLoading(true);
    let start = pagination.current * pagination.pageSize - pagination.pageSize;
    if (!params) params = { limit: pagination.pageSize, start, ...pagination };
    try {
      const d = await Service.sub.list(params);
      setLoading(false);
      setPagination({ ...params, total: d.total });
      setSubs(d.data);
    } catch (err) {
      return setLoading(false);
    }
  };

  const handleAdd = (values) => {
    Service.sub.add(values).then((d) => {
      handleCancel();
      get();
    });
  };

  const handleEdit = (values) => {
    Service.sub
      .update(sub._id, values)
      .then((d) => {
        handleCancel();
        get();
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  return (
    <>
      <PageHeader
        style={{
          border: "1px solid rgb(235, 237, 240)",
        }}
        onBack={() => {
          props.history.goBack();
        }}
        title="  Sub Category"
        extra={[
          <Button
            key="1"
            sub="primary"
            onClick={() => {
              setVisible({ add: true, edit: false });
            }}
          >
            Add
          </Button>,
        ]}
      />
      <Table
        tableLayout="fixed"
        rowKey={(record) => record._id}
        columns={columns}
        dataSource={subs}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
      />

      {sub ? (
        <EditModal
          visible={visible.edit}
          onCreate={handleEdit}
          onCancel={handleCancel}
          sub={sub}
          image={image}
        />
      ) : (
        <div></div>
      )}
      <AddModal
        visible={visible.add}
        onCreate={handleAdd}
        onCancel={handleCancel}
        image={image}
      />
    </>
  );
};

export default withRouter(SubCategory);
