import React, { useContext, useEffect, useState } from "react";
import { NotificationContainer } from "react-notifications";
import { Layout, Breadcrumb } from "antd";
import Navbar from "../navigation/navbar";
import Sidebar from "../navigation/sidebar";
import { AuthContext } from "../../modules/auth/context";

const { Content, Footer } = Layout;

const PrivateLayout = (props) => {
  const { user, calculate } = useContext(AuthContext);
  const { perms } = props;
  const [crumbs, setCrumbs] = useState([]);

  useEffect(() => {
    // let cms = window.location.pathname.split("/");
    // setCrumbs(cms);
  }, []);
  return (
    <div>
      <NotificationContainer />
      <Layout style={{ minHeight: "100vh" }}>
        <Sidebar user={user} perms={perms} calculate={calculate} />
        <Layout className="site-layout">
          <Navbar user={user} perms={perms} calculate={calculate} />
          <Content style={{ margin: "16px 16px" }}>
            {/* <Breadcrumb style={{ margin: "16px 20px", textAlign: "left" }}>
              {crumbs.map((c) => (
                <Breadcrumb.Item key={c}>{c}</Breadcrumb.Item>
              ))}
            </Breadcrumb> */}
            {calculate(perms) ? (
              <div
                className="site-layout-background"
                style={{ padding: 24, minHeight: 360 }}
              >
                {props.children}
              </div>
            ) : (
              ""
            )}
          </Content>
          <Footer style={{ textAlign: "center" }}>
            Nepdrop © {new Date().getFullYear()} Created by Mr verse
          </Footer>
        </Layout>
      </Layout>
    </div>
  );
};
export default PrivateLayout;
