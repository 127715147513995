import { useState, useContext } from "react";
import { Layout, Menu, message, Spin } from "antd";
import Upload from "./Upload";
import Call from "../../util/Call";
import { LoadingOutlined } from "@ant-design/icons";
import { AuthContext } from "../../modules/auth/context";
import Notification from "./Notification";
const { Header } = Layout;

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  const { setImage, calculate } = useContext(AuthContext);
  const [showUpload, setShowUpload] = useState(false);
  const [showNoti, setShowNoti] = useState(false);

  const [imageUploadLoading, setImageUploadLoading] = useState(false);

  const handleFileUpload = async (values, files) => {
    let image = files[0];
    let formData = new FormData();
    if (!image) {
      return message.error("Must select file");
    }

    setImageUploadLoading(true);
    formData.append("file", image);

    for (let v in values) {
      if (v === "sizes") {
        for (let s of values[v]) {
          formData.append("sizes[]", s);
        }
      } else {
        formData.append(v, values[v]);
      }
    }

    let d = await Call({ path: "upload", method: "post", data: formData });
    setImage(d);
    setImageUploadLoading(false);
    message.success("Uploaded");
    return d;
  };

  return (
    <Header className="site-layout-background" style={{ padding: 0 }}>
      <Menu theme="light" mode="horizontal" defaultSelectedKeys={["2"]}>
        {calculate(["image_upload"]) ? (
          <Menu.Item onClick={() => setShowUpload(true)}>
            <Spin
              spinning={imageUploadLoading}
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
            <span> Image Upload</span>
          </Menu.Item>
        ) : (
          ""
        )}
        {calculate(["notification_create"]) ? (
          <Menu.Item onClick={() => setShowNoti(true)}>
            <span> Notification</span>
          </Menu.Item>
        ) : (
          ""
        )}
      </Menu>
      <Upload
        visible={showUpload}
        onCreate={handleFileUpload}
        onCancel={() => setShowUpload(false)}
      />

      <Notification visible={showNoti} onCancel={() => setShowNoti(false)} />
    </Header>
  );
}
