import Call from "../../util/Call";

const listRole = (params) => {
  return Call({
    path: "auth/role/",
    method: "get",
    params,
  });
};

const addRole = (data) => {
  return Call({
    path: "auth/role/",
    method: "post",
    data,
  });
};

const editRole = (id, data) => {
  return Call({
    path: "auth/role/" + id,
    method: "patch",
    data,
  });
};

const logout = () => {
  localStorage.removeItem("token");
};

const user = () => {
  return Call({
    path: "user/me",
    method: "get",
  });
};

const removePerm = (name, data) => {
  return Call({ path: `auth/role/${name}/permissions`, method: "post", data });
};

const removeRole = (id) => {
  return Call({ path: `auth/role/${id}`, method: "delete" });
};

export default {
  listRole,
  logout,
  user,
  addRole,
  editRole,
  removePerm,
  removeRole,
};
