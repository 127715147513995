import Call from "../../util/Call";

export default {
  list: (params) => Call({ path: "notification/", method: "get", params }),

  add: (data) => Call({ path: "notification/", method: "post", data }),

  update: (id, data) =>
    Call({ path: "notification/" + id, method: "patch", data }),
  changeStatus: (id, data) =>
    Call({ path: "notification/" + id + "/status", method: "patch", data }),

  remove: (id) => Call({ path: "notification/" + id, method: "delete" }),
};
