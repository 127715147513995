const obj = {
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAIL: "LOGIN_FAIL",
  LOGOUT: "LOGOUT",
  UPDATE_ROLE: "update_role",
  CLEAR_MESSAGE: "CLEAR_MESSAGE",
  SET_MESSAGE: "SET_MESSAGE",
  SET_LOADING: "set_loading",
  RESET_LOADING: "reset_loading",
  LIST_ROLE: "list_role",
  USER_LOAD: "user_load",
  SET_IMAGE: "set_image",
};

export default obj;
