import Axios from "axios";
import { message } from "antd";

const API_URL = `${process.env.REACT_APP_SERVER_URL}/`;
export default function call({ path, method, data, params, redirect = true }) {
  return new Promise((resolve, reject) => {
    Axios({
      url: API_URL + path,
      method,
      headers: {
        token: localStorage.getItem("token") || "",
      },
      params,
      data,
    })
      .then((d) => resolve(d.data))
      .catch((err) => {
        let status = err?.response?.status;
        if ((redirect && status === 403) || (redirect && status === 401))
          window.location.href = "/login";
        let e = err?.response?.data;
        if (!e) return;
        switch (e.type) {
          case "array":
            for (let m of e.messages) {
              message.error(m);
            }
            break;

          default:
            message.error(typeof e === "string" ? e : e.msg);
            break;
        }
        reject(e);
      });
  });
}
