import React, { useContext } from "react";
import { Navigate } from "react-router-dom";

import { AuthContext } from "../../modules/auth/context";
import PrivateLayout from "./PrivateLayout";
import Loading from "./Loading";

const PrivateRoute = ({ children, perms }) => {
  const { calculate, user } = useContext(AuthContext);
  const isAuth = calculate(perms);
  if (!user) return <Loading />;
  if (!isAuth) {
    return <Navigate to={"/login"} replace />;
  }
  return <PrivateLayout>{children}</PrivateLayout>;
};
export default PrivateRoute;
