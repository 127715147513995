import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return (
      <Component
        {...props}
        history={{ push: navigate, goBack: () => navigate(-1) }}
        match={{ params }}
        router={{
          location,
        }}
      />
    );
  }

  return ComponentWithRouterProp;
}
