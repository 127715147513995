import Call from "../../util/Call";

export default {
  category: {
    list: (params) => Call({ path: "category/", method: "get", params }),

    add: (data) => Call({ path: "category/", method: "post", data }),

    update: (id, data) =>
      Call({ path: "category/" + id, method: "patch", data }),

    remove: (id) => Call({ path: "category/" + id, method: "delete" }),
  },

  package: {
    list: (params) =>
      Call({ path: "category/package/", method: "get", params }),

    add: (data) => Call({ path: "category/package/", method: "post", data }),

    update: (id, data) =>
      Call({ path: "category/package/" + id, method: "patch", data }),

    remove: (id) => Call({ path: "category/package/" + id, method: "delete" }),
  },
  material: {
    list: (params) =>
      Call({ path: "category/material/", method: "get", params }),

    add: (data) => Call({ path: "category/material/", method: "post", data }),

    update: (id, data) =>
      Call({ path: "category/material/" + id, method: "patch", data }),

    remove: (id) => Call({ path: "category/material/" + id, method: "delete" }),
  },
  sub: {
    list: (params) => Call({ path: "category/sub/", method: "get", params }),

    add: (data) => Call({ path: "category/sub/", method: "post", data }),

    update: (id, data) =>
      Call({ path: "category/sub/" + id, method: "patch", data }),

    remove: (id) => Call({ path: "category/sub/" + id, method: "delete" }),
  },
  type: {
    list: (params) => Call({ path: "category/type/", method: "get", params }),

    add: (data) => Call({ path: "category/type/", method: "post", data }),

    update: (id, data) =>
      Call({ path: "category/type/" + id, method: "patch", data }),

    remove: (id) => Call({ path: "category/type/" + id, method: "delete" }),
  },
  spec: {
    list: (params) => Call({ path: "category/spec/", method: "get", params }),

    add: (data) => Call({ path: "category/spec/", method: "post", data }),

    update: (id, data) =>
      Call({ path: "category/spec/" + id, method: "patch", data }),

    remove: (id) => Call({ path: "category/spec/" + id, method: "delete" }),
  },
  unit: {
    list: (params) => Call({ path: "category/unit/", method: "get", params }),

    add: (data) => Call({ path: "category/unit/", method: "post", data }),

    update: (id, data) =>
      Call({ path: "category/unit/" + id, method: "patch", data }),

    remove: (id) => Call({ path: "category/unit/" + id, method: "delete" }),
  },
};
