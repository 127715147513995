import React, { createContext, useReducer, useEffect } from "react";
import { UAParser } from "ua-parser-js";
import { v4 as uuidv4 } from "uuid";
import authReducer from "./reducer";
import ACTION from "./actions";
import Service from "./service";
const initialState = {
  roles: [],
  loading: true,
  user: null,
  images: [],
};

export const AuthContext = createContext(initialState);
const unauthorize = ["/login"];

export const AuthContextProvider = (props) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  useEffect(() => {
    const url = new URL(window.location.href);
    if (!unauthorize.includes(url.pathname)) me();
    return () => {
      // Perform clean-up if needed
    };
  }, []);

  async function updateRole(id, data) {
    let body = await Service.updateRole(id, data);
    dispatch({ type: ACTION.UPDATE_ROLE, body });
    return body;
  }

  function setLoading() {
    dispatch({ type: ACTION.SET_LOADING });
  }

  function setImage(info) {
    dispatch({ type: ACTION.SET_IMAGE, body: info });
  }

  function resetLoading() {
    dispatch({ type: ACTION.RESET_LOADING });
  }

  function addRole(payload) {
    return new Promise((resolve, reject) => {
      Service.addRole(payload)
        .then((body) => {
          resolve(body);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  function editRole(id, payload) {
    return new Promise((resolve, reject) => {
      Service.editRole(id, payload)
        .then((body) => {
          resolve(body);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  function listRole(query) {
    return new Promise((resolve, reject) => {
      Service.listRole(query)
        .then((body) => {
          dispatch({ type: ACTION.LIST_ROLE, body: body.data });
          resolve(body);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function me() {
    setLoading();
    return Service.user()
      .then((d) => {
        dispatch({ type: ACTION.USER_LOAD, body: d });
        resetLoading();
        return d;
      })
      .catch((err) => {
        resetLoading();
        dispatch({ type: ACTION.USER_LOAD, body: null });
        return err;
      });
  }

  function logout() {
    window.localStorage.removeItem("token");
  }

  function setUser(body) {
    dispatch({ type: ACTION.USER_LOAD, body });
  }

  function getDeviceInfo() {
    let device_uuid = window.localStorage.getItem("device_uuid");
    if (!device_uuid) {
      device_uuid = uuidv4();
      window.localStorage.setItem("device_uuid", device_uuid);
    }
    const parser = new UAParser(window.navigator.userAgent);
    const result = parser.getResult();
    delete result.browser.major;
    return {
      device_uuid,
      platform: "browser",
      browser: result.browser,
      type: result.device.type || "desktop",
      operating_system: result.os,
      manufacturer: result.device.vendor,
      model: result.device.model,
      app: "org",
    };
  }

  function calculate(perms) {
    if (state.user === null || state.user === undefined) return null;
    if (!perms || !perms.length) return true;
    if (!state.user) return false;
    if (state.user.is_super_admin) return true;
    else
      return perms.every((element) => state.user.perms.indexOf(element) !== -1);
  }

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        roles: state.roles,
        loading: state.loading,
        images: state.images,
        image: state.images.length ? state.images[state.images.length - 1] : {},
        listRole,
        calculate,
        addRole,
        setLoading,
        resetLoading,
        me,
        logout,
        removeToken: logout,
        editRole,
        setImage,
        setUser,
        updateRole,
        getDeviceInfo,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
